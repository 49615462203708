
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import Breadcrumbs from "../../../common/BreadCrums";
import DataTable from 'react-data-table-component';
import { Link } from "react-router-dom";
import { CCard, CCardBody, CCardGroup } from "@coreui/react";
import NoImgFound from "./../../../assets/No-image-found.jpg"
const UserReviews = (props) => {
    const { members } = props
    console.log('props===>' ,props);
    const columns = [
        {
            name: "Sr. no",
            selector: (row, index) => {
                return index + 1
            },
            sortable: true,
            width: "100px"
        },
        {
            name: 'Name',
            selector: row => row.firstName + ' ' + row.lastName,
            sortable: true,
            actualKey: 'firstName',
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            actualKey: 'email',
        },
        {
            name: 'Image',
            selector: (row) => {
                return <img className="tableImage" src={row.image != '' ? `${process.env.REACT_APP_ASSET_URL}${row.image}` : NoImgFound} />
            }
        },
        {
            name: 'Registeration Type ',
            selector: row => row.register_type,
            sortable: true,
            actualKey: 'register_type',
        },
        {
            name: 'Phone ',
            selector: row => row.phone,
            sortable: true,
            actualKey: 'phone',
        },

        {
            name: 'IsActive ',
            selector: (row) => {
                if (row.isActive) {
                    return <box-icon type='solid' name='user-check'></box-icon>
                }
                return <box-icon name='user-x' type='solid' ></box-icon>
            },
            sortable: true,
            actualKey: 'isActive',
        },
        {
            name: "Actions",
            width: "200px",
            selector: row => (
                <div className="d-flex">

                    <Link to={`users/${row._id}/view`} className="btn btn-sm mx-1 btn-outline-primary" >
                        View
                    </Link>
                    <Link to="#" className="btn btn-sm  btn-outline-danger mx-1" onClick={() => {
                        alert(row._id)
                        // deleteUser(row._id);
                    }}>
                        Delete
                    </Link>
                </div>
            ),
        },
    ];



    return (<>
        <Row>
            <Col xl={12}>
                <CCardGroup>
                    <CCard>
                        <CCardBody>
                            <DataTable
                                columns={columns}
                                data={members}
                                rowsPerPageOptions={[10, 20, 30]}

                            />
                        </CCardBody>
                    </CCard>
                </CCardGroup>

            </Col>

        </Row>
    </>);
}

export default UserReviews;