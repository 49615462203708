import React, { useEffect } from "react";
import { Link } from "react-router-dom";


const Breadcrumbs = (props) => {

    const { parents, curent, title } = props
    return (<>

        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            {title ? <div className="breadcrumb-title pe-3">{title}</div> : ''}
            <div className="ps-3">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0 p-0">

                        {
                            parents?.length ? parents.map((item, index) => {
                                if (item.parent == null) {
                                    return (<>
                                        <li className="breadcrumb-item">
                                            <Link to="/categories">{item.name}</Link>
                                        </li>
                                    </>)
                                }
                                return (<>
                                    <li className="breadcrumb-item">
                                        <Link to={`/subcategories/${item._id}`}>{item.name}</Link>
                                    </li>
                                </>)

                            }) : (
                                ''
                            )
                        }

                        {curent ? <li className="breadcrumb-item active" aria-current="page">{curent?.name}</li> : ''}

                    </ol>

                </nav>
            </div>
        </div>
    </>);

}

export default Breadcrumbs;