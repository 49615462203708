import React, { useEffect, useState } from "react";
import dummyImage from "./../../assets/images/avatar-2.png";
import NoImgFound from "./../../assets/No-image-found.jpg";
import './Users.css';
import Breadcrumbs from "../../common/BreadCrums";
import UserReviews from "./ChildComponets/UserReviews";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserByIdThunk, getUsersCircleThunk } from "../../features/User/userThunk";

const UserView = () => {
    document.title = "User Profile View";
    const [selectedTab, setSelectedTab] = useState('Store');
    const params = useParams();
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer.user);
    const data = useSelector(state => state.userReducer.circle);

    useEffect(() => {
        dispatch(getUserByIdThunk(params.id));
        dispatch(getUsersCircleThunk(params.id));
    }, [params.id, dispatch]);

    useEffect(() => {
        if (data != undefined && data.length > 0) {
            setSelectedTab(data[0].name); // Set the first tab as the default selected tab
        }
    }, [data]);
    
    return (
        <>
            <Breadcrumbs title="User Profile" curent="user detail" />
            <div className="container">
                <div className="main-body">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card pb-2">
                                <div className="card-body">
                                    <div className="d-flex flex-column align-items-center text-center">
                                        <img 
                                            src={user?.image ? process.env.REACT_APP_ASSET_URL + user.image : NoImgFound} 
                                            alt="User" 
                                            className="rounded-circle p-1 bg-primary" 
                                            width="110" 
                                            height="110" 
                                        />
                                        <div className="mt-3">
                                            <h4>{user?.name}</h4>
                                            <p className="text-muted font-size-sm">{user?.email}</p>
                                            <p className="text-muted font-size-sm">{user?.firstName + ' ' + user?.lastName}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="card p-1">
                                {data != undefined && data?.length ? (
                                    <ul className="nav nav-tabs nav-primary justify-content-around" role="tablist">
                                        {data.map((crle, index) => (
                                            <li className="nav-item" key={index}>
                                                <button
                                                    className={`nav-link ${selectedTab === crle.name ? 'active' : ''}`}
                                                    onClick={() => setSelectedTab(crle.name)}
                                                    role="tab"
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <div className="tab-icon">
                                                            <i className='bx bx-store-alt font-18 me-1'></i>
                                                        </div>
                                                        <div className="tab-title">{crle.name}</div>
                                                    </div>
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    'No Circle Found'
                                )}
                                <div className="tab-content py-3">
                                    <div style={{ minHeight: "500px" }}>
                                        <div className="row px-3">
                                            {data != undefined && data?.length ? (
                                                data.map((crle, index) => (
                                                    selectedTab === crle.name ? (
                                                        <UserReviews members={crle.members} key={index} />
                                                    ) : null
                                                ))
                                            ) : (
                                                ' '
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserView;
