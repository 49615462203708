import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { axiosApi } from "../../helper/api_helper";
import * as url from "../../helper/url_helper";
import { uploadImage } from "../Common/ImageUploadThunk";
import { addUsers, deleteUserById, getUsers, getUserById,getUserCircle, updateUserById } from "./userSlice";


export const getUserThunk = createAsyncThunk('users', async (filters, thunkApi) => {
  try {
    const response = await axiosApi.get(url.GET_USERS, filters) 
    thunkApi.dispatch(getUsers(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const getUserByIdThunk = createAsyncThunk('users/edit', async (id, thunkApi) => {
  try {
    
    const response = await axiosApi.get(`${url.GET_USERS}/${id}`)  
    thunkApi.dispatch(getUserById(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});


export const getUsersCircleThunk = createAsyncThunk('users/circle', async (id, thunkApi) => {
  try {
    
    const response = await axiosApi.get(`${url.GET_USER_CIRCLE}/${id}`)  
    thunkApi.dispatch(getUserCircle(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const updateUserByIdThunk = createAsyncThunk('users/update', async (data, thunkApi) => {
  try {
    
    let image = data.image;
    if (data.file) {
      image = await thunkApi.dispatch(uploadImage(data)).unwrap();
    }
    console.log('image ', image)

    const response = await axiosApi.post(`${url.UPDATE_USERS}/${data._id}/edit`, { name: data.name, image });  


    toast.success(response.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(updateUserById(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const deleteUserByIdThunk = createAsyncThunk('users/delete', async (id, thunkApi) => {
  try {

    const response = await axiosApi.delete(`${url.DELETE_CATEGORY}/${id}`);  


    toast.success(response.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(deleteUserById(id))
    return thunkApi.fulfillWithValue(id)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const addUserThunk = createAsyncThunk('users/add', async ({ data }, thunkApi) => {
  try {
    let image = []
    if (data.file) {
      image = await thunkApi.dispatch(uploadImage(data)).unwrap();
    }
    console.log('image ', image)

    const response = await axiosApi.post(url.ADD_USERS, { name: data.name, image });  
    toast.success(response.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(addUsers(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});