import React, { useEffect, useRef, useState } from "react";
import MetisMenu from "metismenujs";
import { Link, withRouter } from "react-router-dom";
import SimpleBar from "simplebar-react";
import Logo from "./../../assets/images/logo/logo.png";

const SideBar = (props) => {
	const [documentHeight, setDocumentHeight] = useState(document.documentElement.clientHeight);
	const ref = useRef();

	const handleMouseOver = () => {
		document.getElementsByClassName("wrapper")[0].classList.add('sidebar-hovered');
	};

	const handleMouseOut = () => {
		document.getElementsByClassName("wrapper")[0].classList.remove('sidebar-hovered');
	};

	useEffect(() => {
		const pathName = props?.location?.pathname;
		const initMenu = () => {
			new MetisMenu(document.getElementById("menu"));
			let matchingMenuItem = null;
			const ul = document.getElementById("menu");
			const items = ul.getElementsByTagName("a");
			for (let i = 0; i < items.length; ++i) {
				if (pathName === items[i]?.pathname) {
					matchingMenuItem = items[i];
					break;
				}
			}
			console.log('matchingMenuItem : ', matchingMenuItem)
			if (matchingMenuItem) {
				activateParentDropdown(matchingMenuItem);
			}
		};
		initMenu();
	}, []);

	useEffect(() => {
		const updateDocumentHeight = () => {
			setDocumentHeight(document.documentElement.clientHeight);
		};
		window.addEventListener('resize', updateDocumentHeight);
		return () => {
			window.removeEventListener('resize', updateDocumentHeight);
		};
	}, [document.documentElement.clientHeight]);


	function scrollElement(item) {
		if (item) {
			const currentPosition = item.offsetTop;
			if (currentPosition > window.innerHeight) {
				ref.current.getScrollElement().scrollTop = currentPosition - 300;
			}
		}
	}

	function activateParentDropdown(item) {
		item.classList.add("active");
		const parent = item.parentElement;
		const parent2El = parent.childNodes[1];
		if (parent2El && parent2El.id !== "menu") {
			parent2El.classList.add("mm-show");
		}

		if (parent) {
			parent.classList.add("mm-active");
			const parent2 = parent.parentElement;

			if (parent2) {
				parent2.classList.add("mm-show"); // ul tag

				const parent3 = parent2.parentElement; // li tag

				if (parent3) {
					parent3.classList.add("mm-active"); // li
					parent3.childNodes[0].classList.add("mm-active"); //a
					const parent4 = parent3.parentElement; // ul
					if (parent4) {
						parent4.classList.add("mm-show"); // ul
						const parent5 = parent4.parentElement;
						if (parent5) {
							parent5.classList.add("mm-show"); // li
							parent5.childNodes[0].classList.add("mm-active"); // a tag
						}
					}
				}
			}
			scrollElement(item);
			return false;
		}
		scrollElement(item);
		return false;
	}

	function tToggle() {
		document.getElementsByClassName('wrapper')[0].classList.toggle('toggled')
	}

	return (
		<React.Fragment>

			<div className="sidebar-wrapper"
				onMouseOver={handleMouseOver}
				onMouseOut={handleMouseOut}
			>
				<SimpleBar className="h-100" ref={ref}>
					<div id="sidebar-menu">
						<div className="sidebar-header">
							<div>
								<img src={Logo} className="logo-icon" alt="logo icon" />
							</div>
							<div>
								<h4 className="logo-text">Admin Panel</h4>
							</div>
							<div className="toggle-icon ms-auto" onClick={() => {
								tToggle();
							}}><i className='bx bx-menu'></i>
							</div>
						</div>
						<ul className="metismenu list-unstyled" id="menu" style={{ 'height': `${documentHeight}px`, 'overflow': 'scroll' }}>
							<li>
								<Link to="/dashboard">
									<div className="parent-icon"><box-icon name='home-smile'></box-icon>
									</div>
									<div className="menu-title">Dashboard</div>
								</Link>
							</li>
							<li>
								<Link to="/#" className="has-arrow">
									<div className="parent-icon"><box-icon type='solid' name='user'></box-icon>
									</div>
									<div className="menu-title">User Management</div>
								</Link>
								<ul className="mm-collapse">
									<li> <Link to="/users"><i className="bx bx-right-arrow-alt"></i>Users</Link>
									</li>

								</ul>

							</li>
							<li>
								<Link to="/#" className="has-arrow">
									<div className="parent-icon">
										<box-icon name='file-doc' type='solid' ></box-icon>
									</div>
									<div className="menu-title">Static Pages</div>
								</Link>
								<ul className="mm-collapse">
									<li> <Link to="/terms&Conditions"><i className="bx bx-right-arrow-alt"></i>Terms & Conditions</Link></li>
									<li> <Link to="/privacyPolicies"><i className="bx bx-right-arrow-alt"></i>Privacy Policy</Link></li>
									<li> <Link to="/faq"><i className="bx bx-right-arrow-alt"></i>FAQ's</Link></li>
								</ul>
							</li>
							<li>
								<Link to="/#" className="has-arrow">
									<div className="parent-icon">
										<box-icon name='cog'></box-icon>
									</div>
									<div className="menu-title">Other Settings</div>
								</Link>
								<ul className="mm-collapse">
									{/* <li> <Link to="/settings/referralProgram"><i className="bx bx-right-arrow-alt"></i>Referral Program</Link></li> */}
								</ul>
							</li>
						</ul>
					</div>
				</SimpleBar>
			</div>
		</React.Fragment>
	);
}

export default withRouter(SideBar)
