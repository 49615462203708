import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/Auth/authSlice'
import loadingReducer from '../features/Loader/loaderSlice'
import userReducer from '../features/User/userSlice'
import cmsReducer from '../features/Cms/cmsSlice'

const store = configureStore({
    reducer: combineReducers({
        authReducer: authReducer,
        loadingReducer: loadingReducer,
        userReducer: userReducer,
        cmsReducer:cmsReducer,
    })
});

export default store;

