import React from "react";
import { Bars } from 'react-loader-spinner'
import { useSelector } from "react-redux";
import "./loader.css"

const Loader = () => {
    const loadingState = useSelector(state => state.loadingReducer.loading);

    return (<>
        <div 
             className={
                `${loadingState ? "loader" : "d-none"} `
              }
              >truetrue
            <Bars
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={loadingState}
            />
        </div>
    </>)
}

export default Loader;