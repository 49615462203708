import Footer from "./partials/Footer"
import React, { useEffect } from "react";
import Header from "./partials/Header"
import SideBar from "./partials/SideBar"
import 'boxicons'

import "./../assets/css/pace.min.css"
import "./../assets/css/bootstrap-extended.css"
import "./../assets/css/app.css"
import 'react-perfect-scrollbar/dist/css/styles.css'
import "./../assets/css/metisMenu.min.css"
import "./../assets/css/dark-theme.css"
import "./../assets/css/semi-dark.css"
import "./../assets/css/header-colors.css"
import "./../assets/css/custom.css"
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "boxicons"
import { ToastContainer } from "react-toastify";
import Loader from "../components/loader/loader";

const AuthLayout = (props) => {


    return (
        <React.Fragment>

            <div className="wrapper">
                <Loader />
                <SideBar />
                <Header />

                <div className="page-wrapper">
                    <div className="page-content">
                        {props.children}
                    </div>
                </div>
                <ToastContainer />
                <Footer />
            </div>
        </React.Fragment>
    )
}

export default AuthLayout
