import React, { useEffect, useState } from "react"
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { getCmsThunk } from "../../features/Cms/cmsThunk";
import { Link } from "react-router-dom";

const TermsConditionsStaticPage = () => {
    const cms = useSelector((state) => state.cmsReducer.cms);
    const dispatch = useDispatch();
    const [data, setData] = useState('')

    // Set meta title
    useEffect(() => {
        document.title = "Terms and Conditions"
    }, []);

    useEffect(() => {
        dispatch(getCmsThunk());
    }, [dispatch]);

    useEffect(() => {
        if (cms) {
            setData(cms?.termsConditions);
        }
    }, [cms]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <div class="row mx-0">
                        <div class="col-md-12 tetx-center pl-0">
                            <h1 class="page_heading new-page-heading  text-center">Terms and Conditions</h1>
                        </div>

                    </div>

                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <div className="mb-3">
                                        {/* Display content as HTML */}
                                        <div dangerouslySetInnerHTML={{ __html: data }} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default TermsConditionsStaticPage;
