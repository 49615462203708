import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cms: [],
}


export const cmsSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        getCms: (state, action) => {
            console.log(action.payload);
            return {
                ...state,
                cms: action.payload
            };
            
        },
        addCms: (state, action) => {
            console.log(action.payload);

            return {
                ...state,
                cms: action.payload,
            };
        }
    }
})


export const { getCms, addCms } = cmsSlice.actions
export default cmsSlice.reducer