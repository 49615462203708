import React from "react"
import "./../assets/css/bootstrap.min.css"
import "./../assets/css/bootstrap-extended.css"
import "./../assets/css/app.css"
import "./../assets/css/icons.css"
import Loader from "../components/loader/loader"

const NonAuthLayout = (props) => {
    return <React.Fragment>
        <Loader />
        {props.children}
    </React.Fragment>
}

export default NonAuthLayout