import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { axiosApi } from "../../helper/api_helper";
import * as url from "../../helper/url_helper";


export const uploadImage = createAsyncThunk('imageUpload', async (data, thunkApi) => {
  try {
    // thunkApi.dispatch()
    console.log('data.file   ', data.file)

    var formdata = new FormData();
    formdata.append("file", data.file, data.file.name);

    const response = await axiosApi.post(url.UPLOAD_IMAGE, formdata, { headers: { "Content-Type": "multipart/form-data" } });

    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});