import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { axiosApi } from "../../helper/api_helper";
import * as url from "../../helper/url_helper";
import { uploadImage } from "../Common/ImageUploadThunk";
import { getCms, addCms } from "./cmsSlice";


export const getCmsThunk = createAsyncThunk('cms', async (filters, thunkApi) => {
  try {
    const response = await axiosApi.get(url.GET_CMS, filters)
    thunkApi.dispatch(getCms(response.data))
    return thunkApi.fulfillWithValue(response.data)
  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});


export const addCmsThunk = createAsyncThunk('cms/add', async ( data , thunkApi) => {
  try {
    const response = await axiosApi.post(url.ADD_CMS,data);
    thunkApi.dispatch(addCms(response.data))

    toast.success(response.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});