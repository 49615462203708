import React, { useEffect, useRef, useState } from "react"
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    Label,
} from "reactstrap"
//Import Breadcrumb
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "./style.css"
import { useDispatch, useSelector } from "react-redux"
import { addCmsThunk, getCmsThunk } from "../../features/Cms/cmsThunk";
import { Link, withRouter } from "react-router-dom";

const TermsConditionsUser = props => {
    const cms = useSelector((state) => state.cmsReducer.cms );
    console.log('cmsReducer ' , cms)

    const dispatch = useDispatch();
    const [data, setData] = useState('')
    //meta title
    document.title = "Terms and conditions"

    useEffect(() => {
        dispatch(getCmsThunk())
        console.log(cms);
    }, [])
    useEffect(()=>{
        if(cms){
            setData(cms?.termsConditions)
        }
    },[cms])
    const HandleSubmit = () => {
        console.log(data)
        const obj = {
            'termsConditions':data
        }
        console.log(obj)
        dispatch(addCmsThunk(obj))
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <div class="row mx-0">
                        <div class="col-md-6 pl-0">
                        <h5 class="page_heading new-page-heading">Terms and Conditions</h5>
                        </div>
                        <div class="col-md-6 pl-0 text-right" style={{textAlign : 'right'}}>
                        <Link to="/termsAndConditions" className="has-arrow" target="_blank">Terms and Conditions</Link>
                        </div>
                        
                    </div>
                            <Row>
                                <Col xl={12}>
                                    <Card>
                                        <CardBody>
                                                <div className="mb-3">
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={cms?.termsConditions != undefined ? cms?.termsConditions : ''}
                                                        onReady={editor => {
                                                            // You can store the "editor" and use when it is needed.
                                                        }}
                                                        onChange={(event, editor) => {
                                                            const editedData = editor.getData();
                                                            console.log({editedData});
                                                            console.log(data);
                                                            setData(editedData)
                                                        }}
                                                        onBlur={(event, editor) => {
                                                        }}
                                                        onFocus={(event, editor) => {
                                                        }}
                                                    />
                                                </div>

                                                <div className="pt-5">
                                                    <button
                                                        type="submit"
                                                        onClick={()=>{
                                                            HandleSubmit()
                                                        }}
                                                        className="btn btn-primary w-md"
                                                    >
                                                        Update
                                                    </button>
                                                </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment>
    )
}


export default TermsConditionsUser

