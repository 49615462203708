import React from "react";
import { Link } from "react-router-dom";
import PerfectScrollbar from 'react-perfect-scrollbar'
const Header = () => {

    const toggleNavMobile = async ()=>{
        document.getElementsByClassName("wrapper")[0].classList.add('toggled');
    }
    
    return (
        <React.Fragment>
            <header>
                <div className="topbar d-flex align-items-center">
                    <nav className="navbar navbar-expand">
                        <div className="mobile-toggle-menu" onClick={toggleNavMobile}><i className='bx bx-menu'></i>
                        </div>
                        <div className="top-menu ms-auto">
                          
                        </div>
                        <div className="user-box dropdown">
                            <Link className="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <box-icon type='solid' name='user-circle'></box-icon>
                                    <div className="user-info ps-3">
                                        <p className="user-name mb-0">Admin</p>
                                        <p className="designattion mb-0">Tranzparency Admin</p>
                                    </div>
                            </Link>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li>
                                    <Link className="dropdown-item" to="/logout">
                                        <i className='bx bx-log-out-circle'></i>
                                        <span>Logout</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        </React.Fragment>
    )
}

export default Header