import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading : false
}

export const  loaderSlice = createSlice({
    name : 'loader',
    initialState,
    reducers :{
        isloading : (state , action) => {
            
            return {
                ...state,
                loading :action.payload
              };
        },
       
    }
})


export const {isloading} = loaderSlice.actions
export default loaderSlice.reducer