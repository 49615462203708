import { useFormik } from "formik";
import React from "react"
import { Link } from "react-router-dom"
import * as Yup from 'yup';
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { signupUser } from "../features/Auth/authThunk";
import { useHistory } from "react-router-dom";
const AuthSignUp = (props) => {
    document.title = "Admin | Signup"
    const dispatch = useDispatch();
    const history = useHistory();
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Your Name"),
            email: Yup.string().required("Please Enter Your Email"),
            password: Yup.string().required("Please Enter Your Password"),
            confirmPassword: Yup.string().required().oneOf([Yup.ref('password'), null], 'Passwords must match')
        }),
        onSubmit: values => {
            dispatch(signupUser(values))
            .unwrap()
            .then((response) => {
               history.push('/login');
            }).catch((err) => {
                toast('shkjshjksh')
            });
        },
    });



    return (
        <React.Fragment>
            <div className="wrapper bg-login">
                <div className="d-flex align-items-center justify-content-center my-5 my-lg-0">
                    <div className="container">
                        <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-2">
                            <div className="col mx-auto">
                                <div className="my-4 text-center">
                                    <img src="assets/images/logo-img.png" width="180" alt="" />
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="border p-4 rounded">
                                            <div className="text-center">
                                                <h3 className="">Sign Up</h3>
                                                <p>Already have an account? <Link to="/login">Sign in here</Link>
                                                </p>
                                            </div>
                                            <div className="login-separater text-center mb-4"> 
                                                <hr />
                                            </div>
                                            <div className="form-body">
                                                <form className="row g-3" onSubmit={formik.handleSubmit}>
                                                    <div className="col-12">
                                                        <label for="InputName" className="form-label">Name</label>
                                                        <input type="name" name="name" className="form-control" onChange={formik.handleChange} id="InputName" placeholder="Jhon" value={formik.values.name}/>
                                                        {formik.touched.name && formik.errors.name ? (
                                                            <div className="text-danger">{formik.errors.name}</div>
                                                        ) : null}
                                                        </div>
                                                    <div className="col-12">
                                                        <label for="inputEmailAddress" className="form-label">Email Address</label>
                                                        <input type="email" name="email" className="form-control" id="inputEmailAddress" onChange={formik.handleChange}  placeholder="example@user.com" value={formik.values.email}/>
                                                        {formik.touched.email && formik.errors.email ? (
                                                            <div className="text-danger">{formik.errors.email}</div>
                                                        ) : null}
                                                        </div>
                                                    <div className="col-12">
                                                        <label for="inputChoosePassword" className="form-label">Password</label>
                                                        <div className="input-group" id="show_hide_password">
                                                            <input type="password" name="password" className="form-control border-end-0" value={formik.values.password} onChange={formik.handleChange}  id="inputChoosePassword" placeholder="Enter Password"/> 
                                                            <a href="javascript:;" className="input-group-text bg-transparent"><i className='bx bx-hide'></i></a>
                                                            
                                                        </div>
                                                        {formik.touched.password && formik.errors.password ? (
                                                            <div className="text-danger">{formik.errors.password}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-12">
                                                        <label for="inputconfirmPassword" className="form-label">Confirm Password</label>
                                                        <div className="input-group" id="show_hide_password">
                                                            <input type="password" name="confirmPassword" value={formik.values.confirmPassword} className="form-control border-end-0" onChange={formik.handleChange} id="inputconfirmPassword" placeholder="Enter Password"/> <a href="javascript:;" className="input-group-text bg-transparent"><i className='bx bx-hide'></i></a>
                                                            
                                                        </div>
                                                        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                                            <div className="text-danger">{formik.errors.confirmPassword}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked"/>
                                                                <label className="form-check-label" for="flexSwitchCheckChecked">I read and agree to Terms & Conditions</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="d-grid">
                                                            <button type="submit" className="btn btn-primary"><i className='bx bx-user'></i>Sign up</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    )
}

export default AuthSignUp