import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"


//redux

const Logout = props => {
  let history = useHistory();

  useEffect(() => {
    localStorage.clear()
    history.push('/login')
  }, [])

  return <></>
}


export default Logout
