
import { Redirect } from 'react-router-dom';
import AuthLogin from '../Auth/AuthLogin'
import AuthSignUp from '../Auth/AuthSignUp';
import Dashboard from '../components/dashboard/dashboard';
import Users from '../components/users/Users';
import NotFound from '../components/NotFound';
import UserView from '../components/users/UserView';
import Logout from '../Auth/logout';
import PrivacyPolicies from "../components/staticPages/PrivacyPolicies"
import TermsConditionsUser from "../components/staticPages/TermsConditionsUser"
import TermsConditionsStaticPage from '../components/staticPages/TermsConditionsStaticPage';
import PrivacyPolicyStaticPage from '../components/staticPages/PrivacyPolicyStaticPage';

const normalRoutes = [
    { path: '/login', component: AuthLogin },
    { path: '/termsAndConditions', component: TermsConditionsStaticPage },
    { path: '/privacyPolicy', component: PrivacyPolicyStaticPage },
    { path: '/signup', component: AuthSignUp },
    { path: '/logout', component: Logout },
    { path: '/', component: () => <Redirect to="/login" /> },
    { path: '*', component: NotFound },
];

const authProtectedRoutes = [
    { path: '/dashboard', component: Dashboard },
    { path: '/users', component: Users },
    { path: '/users/:id/view', component: UserView },
    { path: '/privacyPolicies', component: PrivacyPolicies },
    { path: '/terms&conditions', component: TermsConditionsUser },
    // { path: '/in-app-subscriptions', component: InAppSubscriptions },
    // { path: '/in-app-subscriptions/add', component: AddInAppSubscriptions },
    // { path: '/in-app-subscriptions/:id/edit', component: EditInAppSubscriptions }



];

export { normalRoutes, authProtectedRoutes }