import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";


const Dashboard = () => {

   
    document.title = "Admin Dashboard"
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
                    <div className="col">
                        <div className="card radius-10 border-start border-0 border-3 border-info">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <p className="mb-0 text-secondary">Total Users</p>
                                        <h4 className="my-1 text-info">0</h4>
                                    </div>
                                    <div className="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto"><i className='bx bx-user'></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card radius-10 border-start border-0 border-3 border-danger">
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <p className="mb-0 text-secondary">SOS Alerts</p>
                                        <h4 className="my-1 text-danger">0</h4>
                                    </div>
                                    <div className="widgets-icons-2 rounded-circle bg-gradient-bloody text-white ms-auto"><i className='bx bx-broadcast'></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Dashboard;